.react-tel-input .form-control{
    /* padding-left: 20px; */
    width: 100% !important;
    padding: 20px 50px;
    border-color:#3b366f;
    margin-top: 10px;
  }
  
  .phone-input, .select-flags{
    margin-bottom: 15px;
  }
  
  .mobile-input-container{
    margin-top: 23px !important;
  }

  /* .flag-dropdown {
      border: 0px;
  } */

  .react-tel-input .flag-dropdown {
    border-color:#3b366f;
      border-right: 0px;
      background-color: white;
  }