@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./assets/fonts/Jost/Jost-Regular.ttf") format("truetype");
}

* {
  margin: 0px;
  padding: 0px;
  border: 0;
}

body {
  font-family: "Jost";
  color: #3b366f;
  font-size: 15px;
  background-color: #ffffff;
}

/* BACKGROUND COLOR  */

.background-yellow {
  background: #fec72fed;
}

.height-fullscreen {
  height: 100vh;
}

.bgBlue {
  background: #3b366f;
  color: #ffffff;
}

.bgYellow {
  background: #fec72fed;
}

.bgMaroon {
  background: #9e3030;
}

.bgFaintYellow {
  background: #fff4e5;
}

.bgPurple {
  background: #b5d7f7;
}

.bgGray {
  background: #fce9e9;
}

/* TEXT COLOR  */

.textBlue {
  color: #3b366f !important;
}

.textYellow {
  color: #fce934;
}

.textMaroon {
  color: #9e3030;
}

/* FONT SIZE  */

.font-13px {
  font-size: 13px;
}

/* BORDER RADIUS  */

.borderRadius-5px {
  border-radius: 5px;
}

.borderRadiusTop-5px {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.redirectTrans {
  color: #2563eb !important;
}

.borderRadiusBottom-5px {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.borderRadiusLeft-5px {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.borderRadiusRight-5px {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.borderRadius-10px {
  border-radius: 10px;
}

.borderRadiusTop-10px {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.borderRadiusBottom-10px {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.borderRadiusRight-10px {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.borderRadiusLeft-10px {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.borderRadius-20px {
  border-radius: 20px;
}

.borderRadiusTop-20px {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.borderRadiusBottom-20px {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.borderRadius-50px {
  border-radius: 50px;
}

.borderRadiusTop-50px {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.borderRadiusBottom-50px {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.activeLink {
  background: #3b366f;
  border-radius: 5px;
  color: #ffffff !important;
}

.RadioblueLagoon:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  outline: 1px solid #059669;
  outline-style: dashed;
  -moz-outline-radius: 10px;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px double white;
  background: #059669;
}

.RadioSherpaBlue:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  outline: 1px solid #02563e;
  outline-style: dashed;
  -moz-outline-radius: 10px;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px double white;
  background: #02563e;
}

.RadioMalachite:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  outline: 1px solid #0fec72;
  outline-style: dashed;
  -moz-outline-radius: 10px;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px double white;
  background: #0fec72;
}

.RadioMilanoRed:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  outline: 1px solid #9e3030;
  outline-style: dashed;
  -moz-outline-radius: 10px;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px double white;
  background: #9e3030;
}

.outlinenone:focus,
.outlinenone:active {
  outline: none;
  box-shadow: none;
  border: 0px solid white;
}

select:focus,
select:active,
input:focus,
input:active {
  outline: none !important;
  border-color: none !important;
  box-shadow: none !important;
}

.ig {
  padding-left: 4px;
  padding-right: 4px;
  background-color: #3b366fde;
  color: rgb(250, 250, 250);
  font-size: 12px;
}

.address-lookup-wrapper {
  margin-top: 20px;
  width: 100%;
  /* border: 1px solid #3B366F, 100% !important; */
}

.address-lookup-label {
  display: block;
  font-weight: bold;
  text-transform: capitalize;
}

.address-lookup-input {
  width: 85%;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 8px;
  border: 1px solid #3b366f !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

.address-lookup-search-button {
  width: 15%;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 8px;
  background: #ffffff;
  border: 1px solid #3b366f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0;
}

.address-lookup-select {
  width: 100% !important;
  margin-top: 5px;
  border: 1px solid #3b366f;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.auth-div img {
  padding: 3px !important;
  background: white !important;
  border-radius: 50% !important;
}

.auth-div {
  padding-left: 5%;
}

.auth-div .row {
  margin-bottom: 5px;
}

.p-menu {
  width: max-content;
}

@media screen and (min-width: 1366px) {
  .overflowY {
    overflow: hidden;
  }
  .header-wrapper {
    min-height: 10vh;
  }
}
@media screen and (min-width: 1600px) {
  .overflowY {
    overflow: hidden;
  }
}
@media screen and (min-width: 1900px) {
  .overflowY {
    overflow: hidden;
  }
}

.mimg {
  width: "10%" !important;
}

.mcode {
  width: "16%" !important;
}

.minput {
  width: "72%" !important;
}

.phone-input {
  margin-bottom: 0px;
}

.logoDimensions {
  width: 220px;
  height: 83px;
}

@media screen and (max-width: 2000px) and (min-width: 1500px) {
  .findmmore {
    position: absolute;
    right: 8%;
    bottom: 20%;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .findmmore {
    position: absolute;
    right: 8%;
    bottom: 12%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 700px) {
  .findmmore {
    position: absolute;
    right: 8%;
    bottom: 12%;
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .findmmore {
    position: absolute;
    right: 100px;
    bottom: 10%;
  }
}

@media screen and (max-width: 990px) {
  .logoDimensions {
    width: 170px;
    height: 83px;
  }
  .btn-full {
    display: none !important;
  }
  #form-mobile {
    padding-right: 8px !important;
  }
  #form-full {
    display: none !important;
  }
}

@media screen and (max-width: 405px) {
  .logoDimensions {
    width: 180px;
    height: 83px;
  }
}

@media screen and (max-width: 365px) {
  .logoDimensions {
    width: 160px;
    height: 83px;
  }
}

@media screen and (max-width: 346px) {
  .logoDimensions {
    width: 140px;
    height: 83px;
  }
}
