.phone-input .form-control{
  /* padding-left: 20px; */
  width: 100% !important;
  padding: 20px 50px;
  border-color:#3b366f;
}

.phone-input, .select-flags{
  margin-bottom: 15px;
}

.mobile-input-container{
  margin-top: 23px !important;
}
