input[type="file"] {
    appearance: none;
    background-color: white !important;
    cursor: none;
    align-items: baseline;
    color: inherit;
    text-overflow: ellipsis;
    white-space: pre;
    text-align: start !important;
    padding: initial;
    border: 0;
    overflow: hidden !important;
    /* display: none; */
}